export function callStatusLabelToHexColor(status_code: string): string {
  const Types = {
    Novos: '#BF000B',
    Retorno: '#CB333B',
    'Tentando contato': '#FF7900',
    'Em atendimento': '#FFB900',
    'Com interesse': '#19CB9E',
    'Processo de compra': '#0D7DC1',
    Descartados: '#c2c2c1',
    Vendidos: '#c2c2c1',
    'Sem Score': '#757574',
  };

  return Types[status_code as keyof typeof Types];
}

export function callStatusCodeToIndex(status_code: string): number {
  const Types = {
    '1': 0,
    '9': 1,
    '6': 2,
    '2': 3,
    '7': 4,
    '8': 5,
    '3': 6,
  };

  return Types[status_code as keyof typeof Types];
}

export function callStatusCodeToHexColor(status_code: string): string {
  const Types = {
    '1': '#BF000B',
    '6': '#FF7900',
    '2': '#FFB900',
    '7': '#19CB9E',
    '8': '#0D7DC1',
    '4': '#c2c2c1',
    '3': '#c2c2c1',
    '9': '#CB333B',
    '10': '#757574',
  };

  return Types[status_code as keyof typeof Types];
}
