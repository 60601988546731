export function statusCodeToStatusLabel(status_code: string): string {
  const Types = {
    '1': 'Novo',
    '2': 'Em atendimento',
    '3': 'Vendido',
    '4': 'Descartado',
    '6': 'Tentando contato',
    '7': 'Com interesse',
    '8': 'Processo de compra',
    '9': 'Retorno',
    '10': 'Sem Score',
  };

  return Types[status_code as keyof typeof Types];
}

export function getStatusByNameOrCode(codeOrName: string) {
  const status = {
    '1': 'novo',
    '2': 'em_atendimento',
    '3': 'vendido',
    '4': 'descartado',
    '6': 'tentando_contato',
    '7': 'com_interesse',
    '8': 'processo_de_compra',
    '9': 'retorno',
  };

  const code = codeOrName.split(' ').join('_').toLowerCase();

  const filter = Object.entries(status).filter(
    (item) => code === item[0] || code === item[1],
  );

  return filter[0];
}
